<template>
  <v-dialog
    v-model="input"
    width="500"
    persistent
  >
    <v-card>
      <v-card-title
        class="headline"
        primary-title
        v-html="content.title"
      />
      <v-alert
        :value="true"
        :color="content.color"
        v-html="content.body"
      />
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          flat
          @click="close"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    content: {
      required: true,
      type: Object,
    },
  },
  data () {
    return {
      input: null,
    }
  },
  mounted: function () {
    this.input = this.value
  },
  methods: {
    close: function () {
      this.input = false
      this.$emit('input', false)
    },
  },
}
</script>
